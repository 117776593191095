import React, { useEffect } from "react";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { Description, InnerWrapper, Layout, Text } from "./styled";
import { navigate } from "gatsby";
import useAnalytics from "@helpers/useAnalytics";
import { clientInvestSdk } from "@helpers/clientInvestSdk";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "gatsby-env-variables";
import { useSelector } from "react-redux";
import {
  zakatPaymentAmountSelector,
  zakatSelector,
} from "@Zakat/store/zakatV2/zakat.selector";
import { isWebCreateZakatPaymentResponse } from "@ifgengineering/client-invest-sdk";
import { toast } from "react-toastify";
import { logException } from "@helpers/logException";

const Dua: React.FC = () => {
  const firePageViewAnalytics = useAnalytics("pageView");
  const zakat = useSelector(zakatSelector);
  const zakatPaymentAmount =
    useSelector(zakatPaymentAmountSelector) ?? zakat?.amountDue ?? 0;

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    firePageView("zakat:dua_pageViewed");
  }, []);

  const handlePayment = async () => {
    try {
      const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
      const { data: paymentResponse } =
        await clientInvestSdk.createZakatPayment({
          amount: Math.round(zakatPaymentAmount * 100), // Convert to pence for Stripe
          currency: zakat?.currency ?? "GBP",
          successUrl: `${location.origin}/referral`,
          cancelUrl: `${location.origin}/app/dua`,
        });

      const isWebPaymentResponse =
        isWebCreateZakatPaymentResponse(paymentResponse);

      if (stripe && isWebPaymentResponse) {
        const { sessionId } = paymentResponse;
        await stripe.redirectToCheckout({ sessionId });
      }
    } catch (error) {
      // Handle error appropriately
      toast.error("Payment initialization failed");
      logException({
        exception: error,
        tag: "zakat:dua_payment_initialization_failed",
      });
    }
  };

  return (
    <Layout>
      <InnerWrapper>
        <Text>Take a moment to just collect your thoughts and pray</Text>
        <Description>
          This is the third pillar of your faith. One of the 5 things that make
          you fulfill your Islam. Make sincere dua now.
        </Description>
      </InnerWrapper>
      <InnerWrapper>
        <FormButton
          text="Ameen, Proceed to Payment"
          onClick={handlePayment}
          leftArrowLabel="Back"
          leftArrowOnClick={() => navigate("/app/payment")}
        />
      </InnerWrapper>
    </Layout>
  );
};

export default Dua;
