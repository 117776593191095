import styled from "styled-components";
import { theme } from "@styles/legacy-theme";

export const Layout = styled.div`
  width: 100%;
  min-height: 70vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const InnerWrapper = styled.div`
  width: 327px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
`;

export const HelpText = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
  margin: 0 0 2rem 0;
  color: ${theme.colors.Primary01};
`;

export const Description = styled.p`
  margin: 0 0 0.3rem 0;
  color: ${theme.colors.Font02};
`;

export const Text = styled.h2<{ align?: string }>`
  width: 100%;
  font-weight: bold;
  color: ${theme.colors.BrandBlue};
  text-align: ${({ align }) => align || "left"};
  margin: 2rem 0 1rem 0;
  font-size: 1.5rem;
  text-transform: capitalize;
`;

export const CardsContainer = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
  gap: 1.4rem;
  margin-bottom: 2rem;
`;

export const Spacer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`;
